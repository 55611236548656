@font-face {
  font-family: 'GeneralSans-Variable';
  src: url('/fonts/GeneralSans-Variable.woff2') format('woff2'),
       url('/fonts/GeneralSans-Variable.woff') format('woff'),
       url('/fonts/GeneralSans-Variable.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}

